import { css } from '@emotion/react'
import React from 'react'

import { ButtonLink } from '../components/common/ButtonLink'
import { Layout } from '../components/global/Layout'
import { Seo } from '../components/global/Seo'
import { rem } from '../styles/mixin.styles'
import { breakpoints, colors, height, space } from '../styles/variables.styles'

const NotFoundPage = () => {
  return (
    <Layout>
      <main css={notFoundStyles}>
        <Seo title="404 NOT FOUND" />
        <div css={pageTitle404Styles}>
          <div className="page-title__inner">
            <h1>404 NOT FOUND</h1>
          </div>
          <p className="page-description--404">お探しのページが見つかりませんでした。</p>
        </div>
        <div className="not-found__content">
          <p>
            お探しのページが削除されたか、URLが間違っている可能性がございます。
            <br />
            ご不便をお掛けしますが、URLをご確認いただくか、TOPページにお戻りください。
          </p>
          <ButtonLink link="/">TOPへ戻る</ButtonLink>
        </div>
      </main>
    </Layout>
  )
}

export default NotFoundPage

export const pageTitle404Styles = css`
  width: min(calc(100% - ${space.md} * 2), ${rem(breakpoints.content)});
  margin: 0 auto;
  text-align: center;
  padding-top: clamp(${space.lg}, 5vw, calc(${space.lg} * 2));
  padding-bottom: clamp(${space.lg}, 5vw, calc(${space.lg} * 2));
  .page-title__inner {
    > h1 {
      font-size: clamp(${rem(30)}, 5vw, ${rem(40)});
      line-height: clamp(${rem(43)}, 6vw, ${rem(58)});
      padding-bottom: ${rem(18)};
    }
  }
  .page-description--404 {
    font-size: clamp(${rem(14)}, 3vw, ${rem(16)});
    font-weight: 700;
    color: ${colors.mainBlue.hex};
  }
`

export const notFoundStyles = css`
  margin-top: clamp(${rem(height.header)}, 7vw, ${rem(height.headerMax)});
  width: 100%;
  padding-bottom: clamp(calc(3 * ${space.md}), 3vw, calc(6 * ${space.lg}));
  .not-found__content {
    text-align: center;
    border-top: ${rem(1)} solid ${colors.grayBorder.hex};
    border-bottom: ${rem(1)} solid ${colors.grayBorder.hex};
    padding: ${rem(80)} 0 ${rem(100)};
    p {
      + * {
        margin-top: ${rem(70)};
      }
    }
  }
`
